/* Skeleton Loader Styles */
.skeleton-loader {
    width: 100%; /* Same as iframe width */
    height: 450px; /* Same as iframe height */
    background-color: #f0f0f0;
    position: absolute; /* Overlay the loader on top of the iframe */
    top: 0;
    left: 0;
    z-index: 1; /* Make sure it appears above the iframe */
    animation: shimmer 2s infinite linear;
    border-radius: 4px; /* Optional: to match the iframe's border radius */
}

/* Shimmer Animation */
@keyframes shimmer {
    0% {
        background-position: -600px 0;
    }
    100% {
        background-position: 600px 0;
    }
}

.skeleton-loader::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
    background-size: 200% 100%;
}

.our-location {
    position: relative; /* Ensure the skeleton loader is positioned correctly */
}

.hasbg {
    position: relative; /* Ensure the skeleton loader is positioned correctly */
}
