.section_home-hero {
    background-color: #32645B !important;
    margin: 0 !important;
}

.w-button {
    color: #fff !important;
    line-height: inherit !important;
    cursor: pointer !important;
    background-color: #3898ec !important;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    display: inline-block !important;
}

.button-group .button {
    background-color: #E2AF35 !important;
    color: #fff !important;
    text-align: center !important;
    text-transform: uppercase !important;
    border-radius: 10px !important;
    padding: .75rem 1.5rem !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    text-decoration: none !important;
    transition: transform .25s, box-shadow .25s !important;
    display: block !important;
    box-shadow: 0 8px 30px rgba(183, 23, 37, .35) !important;
}

.button-group .button:hover {
    transform: scale(.94) !important;
    box-shadow: 0 4px 20px rgba(183, 23, 37, .2) !important;
}

.image-9 {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-bottom: 0 !important;
    max-width: 250px !important;
    display: block !important;
}

.button-group {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 1rem !important;
}

.button-group.is-center {
    justify-content: center !important;
}


.padding-global {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.padding-section-large {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}


.home-hero_component {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative !important;
}

@media screen and (max-width: 479px) {
    .button-group .button {
        text-align: center !important;
        align-self: stretch !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        font-size: .8rem !important;
        border-radius: 10px !important;
    }
}

@media screen and (max-width: 767px) {
    .padding-global {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    .padding-section-large {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
}

@media screen and (max-width: 479px) {
    .button-group .button {
        text-align: center !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        font-size: .8rem !important;
    }

    .image-9 {
        padding-bottom: 70px !important;
    }

    .button-group {
        flex-direction: column !important;
    }
}